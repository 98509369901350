import React from 'react';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.App}>
        <div className={styles.wrapper}>
            <div>
                <a href="https://restore.mafiachat.net"><img className={styles.fadeIn} src='/images/mafiachat_character.jpg' alt='mafia' /></a>
            </div>
            <div>
                <p><a href='https://restore.mafiachat.net/'>복구된 마피아챗 바로가기</a></p>
                <p>
                    <a href='https://cafe.daum.net/mafiachat'>다음 카페</a>
                    <a href='https://open.kakao.com/o/geRe6Cgb'>오픈챗</a>
                    <a href='https://x.com/MafiachatNet'>승리팀 기록</a>
                    <a href='https://x.com/mafiachat_crab'>버그 제보</a>
                </p>
            </div>
        </div>
    </div>
  );
}

export default App;
